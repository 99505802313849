import React from "react";
import { useTranslation } from "react-i18next";

const Home: React.FC = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold">{t("homeHeaderTitle")}</h1>
      <p className="my-4 text-xl">{t("homeHeaderBody1")}</p>
      <p className="my-4 text-xl">{t("homeHeaderBody2")}</p>
      <p className="my-4 text-xl">{t("homeHeaderBody3")}</p>
      <p className="my-4 text-xl">{t("homeHeaderBody4")}</p>
      <p className="my-4 text-xl">{t("homeHeaderBody5")}</p>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <a href="https://play.google.com/store/apps/details?id=com.rbelful">
          <img
            src={require(`../images/get-it-on-google-play/${i18n.resolvedLanguage}.png`)}
            alt="app google play"
            style={{ width: "200px", height: "auto" }} // Set consistent size
          />
        </a>
        <a href="https://apps.apple.com/pt/app/rbelful/id6738304543">
          <img
            src={require(`../images/get-it-on-apple-store/${i18n.resolvedLanguage}.svg`)}
            alt="app app store"
            style={{ width: "200px", height: "auto" }} // Set consistent size
          />
        </a>
      </div>

      <img
        src={require(`../images/icon-400px.png`)}
        alt="Login Screen"
        className="border-2 rounded border-green-600 mt-4 w-full max-w-xs mx-auto"
        style={{ height: "auto" }}
      />
    </div>
  );
};

export default Home;
