import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const Contact: React.FC = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [attachments, setAttachments] = useState<File[]>([]);
  const [loading, setLoading] = useState(false); // Loading state
  const { t } = useTranslation();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setAttachments((prevAttachments) => [
        ...prevAttachments,
        ...Array.from(files),
      ]);
    }
  };

  const handleFileRemove = (index: number) => {
    setAttachments((prevAttachments) =>
      prevAttachments.filter((_, i) => i !== index)
    );
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const apiEndpoint =
      process.env.NODE_ENV === "production"
        ? "/.netlify/functions/send-email"
        : "http://localhost:3001/send-email";

    // Define the type for attachments
    type Attachment = {
      name: string;
      type: string;
      content: string; // Base64 encoded content
    };

    // Initial email data structure
    const emailData = {
      from: "no-reply@rbelful.com",
      to: "rbelful@gmail.com",
      subject: `New message from ${email}`,
      message: message,
      attachments: [] as Attachment[], // This tells TypeScript that attachments is an array of Attachment objects
    };

    // Convert files to base64
    for (let file of attachments) {
      const base64File = await convertToBase64(file);
      emailData.attachments.push({
        name: file.name,
        type: file.type,
        content: base64File.split(",")[1],
      });
    }

    setLoading(true); // Set loading state to true

    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailData),
      });

      if (response.ok) {
        alert(t("emailResponseSuccess"));
        setEmail("");
        setMessage("");
        setAttachments([]);
      } else {
        alert(t("emailResponseError"));
      }
    } catch (error) {
      alert(t("emailResponseError"));
    } finally {
      setLoading(false); // Set loading state to false after email is sent
    }
  };

  // Helper function to convert file to base64
  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string); // Base64 encoded string
      };
      reader.onerror = reject;
      reader.readAsDataURL(file); // This converts the file to base64
    });
  };

  return (
    <div className="container mx-auto p-4 text-center">
      {loading ? (
        // Loading spinner
        <div className="flex justify-center items-center min-h-screen">
          <div className="animate-spin border-t-4 border-blue-500 border-solid w-16 h-16 rounded-full"></div>
        </div>
      ) : (
        // Email form
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <h1 className="text-2xl font-bold mb-4">{t("contactTitle")}</h1>
          <p className="mt-2 text-justify text-xl">{t("contactBodyPart1")}</p>
          <p className="mt-2 text-justify text-xl">{t("contactBodyPart2")}</p>
          <p className="mt-2 text-justify text-xl">{t("contactBodyPart3")}</p>

          <div className="my-4">
            <label
              className="block text-gray-700 text-xl font-bold mb-2"
              htmlFor="email"
            >
              {t("emailTitle")}
            </label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t("emailPlaceholder")}
              required
              className="shadow appearance-none border rounded w-full md:w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-xl font-bold mb-2"
              htmlFor="message"
            >
              {t("messageTitle")}
            </label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              placeholder={t("messagePlaceholder")}
              className="shadow appearance-none border rounded w-full md:w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              rows={5}
            />
          </div>
          <div className="mb-4">
            {/* Hidden File Input */}
            <input
              id="attachments"
              type="file"
              multiple
              onChange={handleFileChange}
              className="hidden"
            />
            {/* Custom Button */}
            <label
              htmlFor="attachments"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer"
            >
              {t("addFiles")}
            </label>
            {/* File List */}
            {attachments.length > 0 && (
              <ul className="mt-4 flex flex-col items-center">
                {attachments.map((file, index) => (
                  <li
                    key={index}
                    className="flex items-center text-gray-700 text-sm mb-1"
                  >
                    <button
                      type="button"
                      onClick={() => handleFileRemove(index)}
                      className="text-red-500 hover:text-red-700 mr-2"
                    >
                      ✖ {/* Red X icon */}
                    </button>
                    <span>{file.name}</span>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div>
            <button
              type="submit"
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              {t("sendEmail")}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default Contact;
